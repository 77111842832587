import request from '@/utils/request'

//登录接口
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

// 退出
export function loginOut() {
  return request({
    url: '/logout',
    method: 'get',
  })
}

// 清除缓存
export function clearTrash() {
  return request({
    url: '/clear_trash',
    method: 'get',
  })
}


// 获取管理员详情

export function getadmininfo() {
  return request({
    url: '/getadmininfo',
    method: 'get',
  })
}

export function getuserinfo() {
  return request({
    url: "/getuserinfo",
    method: "get"
  })
}

// 绑定长连接
export function bind_websocket_id(data) {
  return request({
    url: '/bind_websocket_id',
    method: 'post',
    data
  })
}
