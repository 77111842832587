<template>
  <el-container
    class="tc-admin-layout"
    v-loading.fullscreen.lock="fullscreenLoading"
    :element-loading-text="loadingText"
    @click.native="upclickTiem"
  >
    <!-- 头部 -->
    <!-- <el-header>Header</el-header> -->
    <el-container>
      <!-- 左边侧边栏 -->
      <el-aside width="250px">
        <!-- log图标 -->
        <div class="logo">
          <img :src="imgUrl" width="150px" alt />
        </div>

        <!-- 导航栏 -->
        <el-menu
          :router="true"
          :default-active="'/' + $route.path.split('/')[1]"
          class="el-menu-vertical-demo"
          background-color="transparent"
          text-color="rgb(53, 53, 53)"
          active-text-color="#4876FF"
          :unique-opened="true"
        >
          <div v-for="item in list" :key="item.title">
            <!-- 有二级菜单 -->
            <el-submenu
              :index="'' + item.route"
              v-if="item.children && item.children.length > 0"
            >
              <template slot="title">
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="'' + item2.route"
                  v-for="item2 in item.children"
                  :key="item2.title"
                  >{{ item2.title }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <!-- 没有二级菜单 -->
            <el-menu-item :index="'' + item.route" v-else>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </div>
        </el-menu>
        <!-- 底部工具栏 -->
        <div class="tool">
          <!-- 退出登陆 -->
          <div class="tool-item" @click="exit">
            <i class="el-icon-switch-button"></i>
          </div>
          <div class="tool-item" @click="handleFullScreen">
            <i class="el-icon-warning-outline"></i>
          </div>
          <div class="tool-item">
            <i class="el-icon-setting" @click="cleardata"></i>
          </div>
        </div>
      </el-aside>
      <!-- main内容输出区 -->
      <!-- 返回顶部 -->
      <el-backtop target=".el-main"></el-backtop>
      <el-main>
        <!-- 组件面包屑 -->
        <!-- <crumbs class="breadcrumb-container" /> -->
        <!-- <transition :name="transitionName"> -->
        <transition name="fade-transform" mode="out-in" v-if="load">
          <router-view></router-view>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// 引入组件
import Crumbs from "../components/crumbs"; //面包屑
import axios from "axios";
import { loginOut, clearTrash } from "@/api/login.js";
import { getListConfig, getwarnings } from "@/api/comm.js";
export default {
  components: {
    // Crumbs
  },
  data() {
    return {
      load: false,
      //导航栏没加载成功之前不加载右面版
      loadingText: "系统加载中",
      fullscreenLoading: true,
      path: "",
      transitionName: "",
      // path: "",
      list: [
        {
          id: 101,
          authName: "商品管理",
          path: null,
          children: [
            {
              id: 104,
              authName: "商品列表",
              path: null,
              children: [],
            },
          ],
        },
        {
          id: 102,
          authName: "用户管理",
          path: null,
        },
      ],
      // 屏幕数据
      width: "",
      height: "",
      imgUrl: "",
      warning: null,
      a: null,
      // 监控是否操作数据
      astTime: new Date().getTime(),
      currentTime: new Date().getTime(),
      timeOut: 1000 * 60 * 60 * 3, //设置超时时间： 三个小时
      autoOut: null,
    };
  },
  created() {
    this.loadMenu();
    let config = JSON.parse(localStorage.getItem("config"));

    if (config == null) {
      getListConfig().then((res) => {
        if (res.code == 400200) {
          localStorage.setItem("config", JSON.stringify(res.data));
          this.imgUrl = res.data.logo;
        }
      });
    } else {
      this.imgUrl = config.logo;
    }

    // 获取警告信息
    this.warning = setInterval(() => {
      getwarnings().then((res) => {
        if (res.code == 400201) {
          this.a = this.$notify({
            title: res.msg,
            type: "warning",
            duration: 15000,
            position: "bottom-right",
            message: res.text,
            onClick: () => {
              this.check();
            },
          });
        }
      });
    }, 5000);
    // //console.log(this.$route);
  },
  mounted() {
    this.autoOut = setInterval(() => {
      this.checkTimeout();
    }, 2000);
  },
  methods: {
    //加载导航栏
    async loadMenu() {
      var res = await axios.get("/get_menu_list");
      if (res.data.code == 400200) {
        //   // 移除 不用的菜单
        //   let a = res.data.data.filter((v) => {
        //     if (
        //       v.title != "首页文章管理" &&
        //       v.title != "在线训练管理" &&
        //       v.title != "在线问诊管理"
        //     ) {
        //       return v;
        //     }
        //   });
        //   this.list = a;
        // 正常
        this.list = res.data.data;
        this.fullscreenLoading = false;
        this.load = true;
      } else {
        this.$router.push("/login");
      }
      // //console.log(res);
    },
    // async getSystemWaring() {
    //   //console.log("112");
    // },
    // 退出
    exit() {
      this.$confirm("是否退出系统", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginOut().then((res) => {
            //console.log(res);
            if (res.code === 400200) {
              this.$message({
                type: "success",
                message: "欢迎再次光临!",
              });
              this.socketApi.websock.close();
              localStorage.clear();
              this.$router.push({ path: "/login?type=out" });
            }
          });
        })
        .catch(() => {});
    },
    // 清除缓存
    cleardata() {
      this.$confirm("是否清除缓存", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          clearTrash();
        })
        .catch(() => {});
    },
    // 查看预警，跳转到报告列表页面
    check() {
      // this.$notify.close;
      // console.log(this.$notify);
      this.a.close();
      this.$router.push("/evaluate_report");
    },
    handleFullScreen() {
      let element = document.documentElement;
      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        console.log("已还原！");
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
        console.log("已全屏！");
      }
      // 改变当前全屏状态
      this.fullscreen = !this.fullscreen;
    },
    // 判断是否长时间未动
    checkTimeout() {
      this.currentTime = new Date().getTime(); //更新当前时间
      // if (this.currentTime - this.astTime > this.timeOut) {
      //   clearInterval(this.autoOut);
      //   this.autoOut = null;
      //   clearInterval(this.warning);
      //   this.warning = null;
      //   loginOut().then((res) => {});
      //   this.$alert("长时间未操作系统，已自动退出", "系统通知", {
      //     confirmButtonText: "确定",
      //     type: "warning",
      //     callback: (action) => {
      //       this.$router.push("/login");
      //     },
      //   });
      // }
    },
    // 更新时间
    upclickTiem() {
      this.astTime = new Date().getTime();
      this.currentTime = new Date().getTime();
    },
  },
  watch: {
    //使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.warning);
    this.warning = null;
    clearInterval(this.autoOut);
    this.autoOut = null;
  },
};
</script>

<style lang="less">
.tc-admin-layout {
  width: 100%;
  height: 100%;
  div {
    box-sizing: border-box;
  }
  height: 100%;
  /* 侧栏 */
  .el-aside {
    position: relative;
    // logo
    .logo {
      margin: 0 auto;
      margin-top: 50px;
      height: 100px;
      width: 200px;
      line-height: 100px;
      text-align: center;
      padding: 0 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
    background-image: url("../assets/images/aside-background.png");
    background-size: 1675px 100%;
    background-repeat: no-repeat;
    //导航栏
    .el-menu {
      border-right: none;
    }
    .el-menu-vertical-demo {
      margin-top: 50px;
      height: calc(~"100% - 250px");
      overflow: auto;
      text-align: center;
      &::-webkit-scrollbar {
        display: none;
      }
      .el-icon-arrow-down:before {
        content: "" !important;
        display: none !important;
      }
      > .el-menu-item,
      .el-submenu__title {
        height: 70px;
        line-height: 70px;
      }
      .el-menu-item:hover,
      .el-submenu__title:hover {
        background-color: rgb(242, 250, 255) !important;
      }
    }
    // 导航一级菜单
    .el-submenu__title > span,
    .el-menu-item > span {
      font-weight: 700;
      font-size: 20px;
    }
    // 点击背景
    .is-active {
      transition: all 0.5s !important;
      background: rgb(242, 250, 255) !important;
    }
    // 二级菜单
    .el-menu-item {
      font-size: 20px !important;
    }
    // 底部工具
    .tool {
      margin-top: 1px;
      width: 80%;
      font-size: 30px;
      color: rgb(53, 53, 53);
      position: absolute;
      bottom: 10px;
      left: 0;
      display: flex;
      padding: 0 20px;
      justify-content: space-between;
      .tool-item {
        cursor: pointer;
      }
      .tool-item:nth-child(-n + 2) {
        padding-right: 20px;
        border-right: 1px #cccccc dashed;
      }
    }
  }
  /* 主体 */
  .el-main {
    width: 100% !important;
    height: 100% !important;
    min-width: 800px;
    background: rgb(242, 250, 255) !important;
    padding: 0 20px !important;
  }
}
// @media screen and (max-width: 1440px) {
//     .tc-admin-layout{
// zoom: 0.75;
//     }
// }
// @media screen and (max-width: 1366px) {
//     .tc-admin-layout{
//       // zoom: 0.65;
//     }
// }
</style>
